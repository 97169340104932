import { Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import heroImage from "../images/banner.png"
import playStore from "../images/google-play-badge.png"
import appStore from "../images/app-store-badge.svg"

const IndexPage = () => {
  return (
    <Layout>
      <main className="max-w-4xl flex-grow mx-auto flex flex-col justify-around">
        <div className="sm:flex sm:flex-row-reverse sm:items-center">
          <div className="sm:px-2">
            <h1 className="px-4 pt-5 text-2xl text-left text-green-500 font-bold sm:text-3xl">
              Recupera Tus Puntos
            </h1>
            <p className="px-4 mt-8 text-lg text-gray-700 sm:mt-8">
              Realiza tests para ayudarte a recuperar los puntos de tu carnet de
              conducir.
            </p>
          </div>
          <img
            className="w-full max-w-lg mt-16 mx-auto sm:w-1/2"
            src={heroImage}
            alt="Gatsby and Tailwind CSS together"
          />
        </div>

        <div className="flex justify-center gap-3">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.autoescuela2020.recuperatuspuntos"
          >
            <img
              alt="Disponible en Google Play"
              src={playStore}
              className="h-12"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/us/app/recupera-tus-puntos/id1585337246"
          >
            <img alt="Descargar en App Store" src={appStore} className="h-12" />
          </a>
        </div>

        <div className="flex justify-center  bg-white rounded shadow-xs sm:mx-auto">
          <Link
            to="privacy"
            className="p-3 mx-2 text-gray-600 text-xs leading-xl hover:underline hover:text-black"
          >
            Política de privacidad
          </Link>
          <Link
            to="terms"
            className="p-3 mx-2 text-gray-600 text-xs leading-xl hover:underline hover:text-black"
          >
            Términos y condiciones
          </Link>
          <Link
            to="legal"
            className="p-3 mx-2 text-gray-600 text-xs leading-xl hover:underline hover:text-black"
          >
            Aviso Legal
          </Link>
        </div>
      </main>
    </Layout>
  )
}

export default IndexPage
